import {
    AccountTree,
    Business,
    Campaign,
    Category,
    Lightbulb,
    People,
    RecordVoiceOver,
    VideoSettings,
} from '@mui/icons-material';

export const menus = [
    {
        title: 'Gestão de Campanhas',
        link: '/campanhas',
        accessLevel: 0,
        icon: Campaign,
    },
    {
        title: 'Aprenda',
        link: '/aprenda',
        accessLevel: 0,
        icon: Lightbulb,
    },
    {
        title: 'Cadastro de Clientes',
        link: '/clientes',
        accessLevel: 0,
        icon: Business,
    },
    {
        title: 'Modelos de Escopo',
        link: '/templates',
        accessLevel: 1,
        icon: VideoSettings,
    },
    {
        title: 'Cadastro de Workflows',
        link: '/workflows',
        accessLevel: 1,
        icon: AccountTree,
    },
    {
        title: 'Cadastro de Usuários',
        link: '/usuarios',
        accessLevel: 1,
        icon: People,
    },
    {
        title: 'Cadastro de Creators',
        link: '/creators',
        accessLevel: 0,
        icon: RecordVoiceOver,
    },
    {
        title: 'Tipos de Entrega',
        link: '/escopos',
        accessLevel: 0,
        icon: Category,
    },
];

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Chat } from '@mui/icons-material';
import { Box, Fab, Tab, Tabs, Badge } from '@mui/material';
import { mensagemVazio, showMessage } from '../../data/Interfaces';
import { Get, Patch, Post } from '../../data/Verbs';
import { Alert } from '../../components/common/Alert';
import { Loader } from '../../components/Loader';
import { CampanhaDados } from './CampanhaDados';
import { CampanhaItens } from './CampanhaItens';
import { ChatModal } from '../../components/ChatModal';
import { useAuthStore, useNotificationStore } from '../../store';
import { handleClearStorage, handleOpenClientFromNotification } from '../../utils/handleStorage.utils';
import { useError } from '../../contexts/ErrorContext';
import { apiPostCampanhaItem, apiUpdateCampain } from '../../services';
import CustomBadge from '../../components/common/CustomBadge';
import { apiPostDuplicateCampanhaItem } from '../../services/apiPostDuplicateCampanhaItem';

export const Campanha = () => {
    const { id } = useParams();

    const storageContexto = sessionStorage.getItem('storageContexto');
    const storageCampanha = sessionStorage.getItem('storageCampanha');
    const storageCampanhaItem = sessionStorage.getItem('storageCampanhaItem');

    const isValidStorageValue = (value) => value && value !== 'null';

    const getExpandedValue = () => {
        if (id) {
            return 3;
        }

        if (
            isValidStorageValue(storageContexto) &&
            (isValidStorageValue(storageCampanha) || isValidStorageValue(storageCampanhaItem))
        ) {
            return 1;
        }

        return 0;
    };

    const [expanded, setExpanded] = useState(getExpandedValue);

    useEffect(() => {
        setExpanded(getExpandedValue());
    }, [id, storageContexto, storageCampanha, storageCampanhaItem]);

    const [campanha, setCampanha] = useState({});
    const [clientes, setClientes] = useState([]);
    const [creators, setCreators] = useState([]);
    const [workflows, setWorkflows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [updated, setUpdated] = useState(false);
    const [message, setMessage] = useState(mensagemVazio);
    const [chatModalOpen, setChatModalOpen] = useState(false);
    const [clientNotifications, setClientNotifications] = useState(0);
    const user = useAuthStore((state) => state.user);
    const { notifications, fetchNotifications, getNotificationsByContext } = useNotificationStore((state) => ({
        notifications: state.notifications,
        fetchNotifications: state.fetchNotifications,
        getNotificationsByContext: state.getNotificationsByContext,
    }));

    const navigate = useNavigate();
    const { showError } = useError();

    const handleChangeTab = (event, newValue) => {
        setExpanded(newValue);
    };

    const handleSalvarCampanhaItem = async (campanhaItem) => {
        try {
            await apiPostCampanhaItem(campanhaItem);

            setUpdated((prevUpdated) => !prevUpdated);
        } catch (error) {
            showError(error);
        }
    };

    const handleSubmit = async (handleCampanha) => {
        setIsLoading(true);

        if (handleCampanha.id) {
            try {
                await apiUpdateCampain(handleCampanha.id, handleCampanha);
                showMessage({ variant: 'success', message: 'Campanha alterada com sucesso' }, setMessage);
                setUpdated((prevUpdated) => !prevUpdated);
            } catch (error) {
                showError(error);
            }
        } else {
            try {
                const response = await Post(`${process.env.REACT_APP_API_URL}/campanhas`, handleCampanha);
                showMessage({ variant: 'success', message: 'Campanha inserida com sucesso' }, setMessage);
                navigate(`/campanhas/editar/${response.id}`);
            } catch (error) {
                showError(error);
            }
        }
    };

    const handleChangeCreator = async ({ creatorIdentify, creator, creatorName, valor, escopoBriefing }) => {
        setIsLoading(true);

        try {
            await Post(`${process.env.REACT_APP_API_URL}/campanhasItens/changeCreator`, {
                campanha: campanha.id,
                creatorIdentify,
                creator,
                valor,
                creatorName,
                escopoBriefing,
            });

            setUpdated((prevUpdated) => !prevUpdated);
        } catch (error) {
            showError(error);
        }
    };

    const handleDuplicarCampanhaItem = async (creatorIdentify, campanhaItens) => {
        setIsLoading(true);

        try {
            const itemsToDuplicate = campanhaItens.filter((item) => item.creatorIdentify === creatorIdentify);
            const idsToDuplicate = itemsToDuplicate.map((item) => item.id);
            await apiPostDuplicateCampanhaItem(idsToDuplicate);
            setUpdated((prevUpdated) => !prevUpdated);
        } catch (error) {
            showError(error);
        }
    };

    const handleDeletarCampanhaItens = async (idsToDelete) => {
        setIsLoading(true);

        try {
            await Post(`${process.env.REACT_APP_API_URL}/campanhasItens/deleteMultiple`, {
                ids: idsToDelete,
            });

            fetchNotifications();

            setUpdated((prevUpdated) => !prevUpdated);
        } catch (error) {
            showError(error);
        }
    };

    const handleChatButtonClick = () => {
        setChatModalOpen(true);
    };

    const handleCloseChat = () => {
        setChatModalOpen(false);
    };

    const renderCampanhaItens = (index, pendingFilterActive = false) => {
        return (
            expanded === index &&
            campanha?.id > 0 &&
            workflows?.length > 0 && (
                <CampanhaItens
                    campanha={campanha}
                    creators={creators}
                    workflows={workflows}
                    handleSalvarCampanhaItem={handleSalvarCampanhaItem}
                    handleDeletarCampanhaItens={handleDeletarCampanhaItens}
                    handleDuplicarCampanhaItem={handleDuplicarCampanhaItem}
                    handleChangeCreator={handleChangeCreator}
                    tab={expanded}
                    pendingFilterActive={pendingFilterActive}
                    updated={updated}
                    setUpdated={setUpdated}
                />
            )
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dataWorkflows = await Get(`${process.env.REACT_APP_API_URL}/workflows`);
                const dataClientes = await Get(`${process.env.REACT_APP_API_URL}/clientes?sort=nome_asc`);
                const dataCreators = await Get(`${process.env.REACT_APP_API_URL}/creators?sort=nome_asc`);

                setWorkflows(dataWorkflows);
                setClientes(dataClientes);
                setCreators(dataCreators);

                if (id) {
                    const dataCampanha = await Get(`${process.env.REACT_APP_API_URL}/campanhas/${id}`);
                    setCampanha(dataCampanha);
                }

                const notificationContext = await getNotificationsByContext('ADMIN_CLIENTE_CAMPANHA');
                setClientNotifications(notificationContext);
            } catch (error) {
                showError(error);
            }
        };

        if (handleOpenClientFromNotification()) {
            handleChatButtonClick();
            handleClearStorage();
        }

        fetchData();

        setIsLoading(false);
    }, [id, updated, getNotificationsByContext, showError]);

    useEffect(() => {
        const fetchNotifications = async () => {
            const clientNotifications = await getNotificationsByContext('ADMIN_CLIENTE_CAMPANHA');
            setClientNotifications(clientNotifications);
            setIsLoading(false);
        };

        fetchNotifications();
    }, [notifications, getNotificationsByContext]);

    return (
        <>
            {isLoading && <Loader />}

            <>
                <Box sx={{ margin: 2 }}>
                    {message.message && <Alert variant={message.variant} message={message.message} />}

                    <Tabs value={expanded} onChange={handleChangeTab} aria-label="campanha tabs" variant="scrollable">
                        <Tab label="Dados de Campanha" value={0} sx={{ color: 'var(--primary-dark-color)' }} />
                        {id > 0 && [
                            <Tab
                                label="Creators"
                                value={1}
                                key="creators"
                                sx={{ color: 'var(--primary-dark-color)' }}
                            />,
                            <Tab
                                label="Todas as Entregas"
                                value={2}
                                key="entregaveis"
                                sx={{ color: 'var(--primary-dark-color)' }}
                            />,
                            <Tab
                                label="Minhas Pendências"
                                value={3}
                                key="pendentes"
                                sx={{ color: 'var(--primary-dark-color)' }}
                            />,
                            <Tab
                                label="Pendências do cliente"
                                value={4}
                                key="pendenciasCliente"
                                sx={{ color: 'var(--primary-dark-color)' }}
                            />,
                        ]}
                    </Tabs>

                    <TabPanel value={expanded} index={0}>
                        <CampanhaDados _handleSubmit={handleSubmit} campanha={campanha} clientes={clientes} />
                    </TabPanel>

                    <TabPanel value={expanded} index={1}>
                        {renderCampanhaItens(1)}
                    </TabPanel>

                    <TabPanel value={expanded} index={2}>
                        {renderCampanhaItens(2)}
                    </TabPanel>

                    <TabPanel value={expanded} index={3}>
                        {renderCampanhaItens(3, true)}
                    </TabPanel>

                    <TabPanel value={expanded} index={4}>
                        {renderCampanhaItens(4)}
                    </TabPanel>
                </Box>

                <ChatModal
                    headerTitle={user.nome}
                    item={campanha}
                    openChat={chatModalOpen}
                    toggleDrawer={handleCloseChat}
                    contexto="ADMIN_CLIENTE_CAMPANHA"
                />

                <CustomBadge
                    badgeContent={clientNotifications}
                    Icon={Chat}
                    isFab
                    fabProps={{
                        color: 'success',
                        'aria-label': 'chat',
                        sx: {
                            position: 'fixed',
                            bottom: 30,
                            right: 30,
                            backgroundColor: 'var(--primary-color)',
                            '&:hover': {
                                backgroundColor: 'var(--primary-light-color)',
                            },
                        },
                        onClick: handleChatButtonClick,
                    }}
                />
            </>
        </>
    );
};

function TabPanel(props) {
    const { sx, children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ ...sx }}>{children}</Box>}
        </div>
    );
}
